<template>
    <section class="what-we-do-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="what-we-do-content">
                        <span class="sub-title">{{ onPage.content.block_title }}</span>
                        <h2>{{ onPage.content.title }}</h2>
                        <p>{{ onPage.content.description }}</p>
                        <div class="skills-item">
                            <div class="skills-header">
                                <h4 class="skills-title">{{ onPage.content.skill_title }}</h4>
                                <div class="skills-percentage">
                                    <div class="count-box">{{ onPage.content.skill_number }}%</div>
                                </div>
                            </div>
                            <div class="skills-bar">
                                <div class="bar-inner"><div class="bar progress-line" :data-width="onPage.content.skill_number"></div></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="what-we-do-content-accordion">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        <i class='flaticon-plus'></i>
                                        {{ onPage.content.optimization_title }}
                                        <span></span>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>{{ onPage.content.optimization_content }}</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        <i class='flaticon-plus'></i>
                                        {{ onPage.content.generation_title }}
                                        <span></span>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>{{ onPage.content.generation_content  }}</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        <i class='flaticon-plus'></i>
                                        {{ onPage.content.services_title }}
                                        <span></span>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>{{ onPage.content.services_content }}</p>
                                </template>
                            </accordion-item>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'WhatWeDo',
    components: {
        Accordion,
        AccordionItem
    },

    setup(){

        const onPage = reactive({
            content: '',
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{

            let whatWeDo = await loadInfo('what-we-do'); // url

            onPage.content = whatWeDo.data.attributes

        })

        return{
            onPage
        }
    }
}
</script>