<template>
    <div class="seo-analysis-area bg-color ptb-100">
        <div class="container">

            <div class="row align-items-center" v-if="onPage.content != ''">
                <div class="col-lg-6 col-md-12">
                    <div class="analysis-img">
                        <img :src="'https://strapi.avalon.co.il'+onPage.content.image.data.attributes.url" alt="">
                        <!-- <img src="../../assets/images/seo-analysis.png" alt="Image"> -->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="analysis-form">
                        <span class="sub-title">{{ onPage.content.subtitle }}</span>
                        <h2>{{ onPage.content.title }}</h2>
                        <div v-html="onPage.content.description"></div>
                        <form>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="your_name" :placeholder="onPage.content.name_placeholder">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" class="form-control" id="your_email" :placeholder="onPage.content.email_placeholder">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="your_name" :placeholder="onPage.content.phone_placeholder">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="website" :placeholder="onPage.content.website_placeholder">
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="default-btn">{{ onPage.content.btn_title }}</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>


import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'GetFreeAnalysis',

    setup(){

        const onPage = reactive({
            content: '',
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{

            let content = await loadInfo('service-detail-analysis?populate=image')
            
            onPage.content = content.data.attributes
        })


        return{
            onPage
        }
    }
}
</script>