<template>
    <div>
        <NavbarStyleFour />
        <PageTitle />
        <ContactInfo />
        <ContactContent />
        <Map />
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleFour from '../Layout/NavbarStyleFour'
    import PageTitle from '../ContactUs/PageTitle'
    import ContactInfo from '../ContactUs/ContactInfo'
    import ContactContent from '../ContactUs/ContactContent'
    import Map from '../ContactUs/Map'
    import Footer from '../Layout/Footer'

    export default {
        name: 'ContactUsPage',
        components: {
            NavbarStyleFour,
            PageTitle,
            ContactInfo,
            ContactContent,
            Map,
            Footer,
        }
    }
</script>