<template>
    <div class="pricing-area bg-f9f9f9 pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">{{ onPage.header.block_title }}</span>
                <h2>{{ onPage.header.title }}</h2>
                <p>{{ onPage.header.description }}</p>
            </div>

            <div class="row">

                <div class="col-lg-4 col-md-6 col-sm-6" v-for="item in onPage.content" :key="item.id">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <div class="icon">
                                <i :class="item.attributes.icon"></i>
                                <div class="circles-box">
                                    <div class="circle-one"></div>
                                </div>
                            </div>
                            <h3>{{ item.attributes.title }}</h3>
                        </div>

                        <div class="pricing-features">
                            <ul>
                                <li>{{ item.attributes.option1 }}</li>
                                <li>{{ item.attributes.option2 }}</li>
                                <li>{{ item.attributes.option3 }}</li>
                                <li>{{ item.attributes.option4 }}</li>
                                <li>{{ item.attributes.option5 }}</li>
                            </ul>
                        </div>

                        <div class="price">
                            {{ item.attributes.price }}
                            <span>{{ item.attributes.period }}</span>
                        </div>

                        <a :href="item.attributes.btn_url" class="default-btn">{{ item.attributes.btn_title }}</a>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'OurFlexiblePricingPlan',

    setup(){

        const onPage = reactive({
            header: '',
            content: '',
        })

        const { loadInfo } = connectAxios()

        onMounted(async() => {

            let header = await loadInfo('our-flexible-pricing-plan');
            onPage.header = header.data.attributes;

            let content = await loadInfo('our-flexible-pricing-plan-contents?sort=id')
            onPage.content = content.data

        })


        return{
            onPage

        }
    }
}
</script>