<template>
    <footer class="footer-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <router-link to="/" class="logo">
                            <img :src="onPage.icon" alt="logo">
                        </router-link>
                        <p>{{ onPage.content.description }}</p>

                        <ul class="social-link">
                            <li><a :href="onPage.content.facebook" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a :href="onPage.content.twitter" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a :href="onPage.content.instagram" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a :href="onPage.content.linkedin" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-5">
                        <h3>{{ onPage.content.explore_title }}</h3>

                        <ul class="footer-links-list">
                            <li><a :href="onPage.content.explore_option1_url">{{ onPage.content.explore_option1 }}</a></li>
                            <li><a :href="onPage.content.explore_option2_url">{{ onPage.content.explore_option2 }}</a></li>
                            <li><a :href="onPage.content.explore_option3_url">{{ onPage.content.explore_option3 }}</a></li>
                            <li><a :href="onPage.content.explore_option4_url">{{ onPage.content.explore_option4 }}</a></li>
                            <li><a :href="onPage.content.explore_option5_url">{{ onPage.content.explore_option5 }}</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-2">
                        <h3>{{ onPage.content.resources_title }}</h3>

                        <ul class="footer-links-list">
                            <li><a :href="onPage.content.resourse_option1_url">{{ onPage.content.resourse_option1 }}</a></li>
                            <li><a :href="onPage.content.resourse_option2_url">{{ onPage.content.resourse_option2 }}</a></li>
                            <li><a :href="onPage.content.resourse_option3_url">{{ onPage.content.resourse_option3 }}</a></li>
                            <li><a :href="onPage.content.resourse_option4_url">{{ onPage.content.resourse_option4 }}</a></li>
                            <li><a :href="onPage.content.resourse_option5_url">{{ onPage.content.resourse_option5 }}</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>{{ onPage.content.address_title }}</h3>

                        <ul class="footer-contact-info" v-if="onPage.content != ''">
                            <li><i class='bx bx-map'></i>{{ onPage.content.address_address }}</li>
                            <li><i class='bx bx-phone-call'></i><a class="directionLtr" :href="toPhone(onPage.content.address_phone)" >{{ onPage.content.address_phone }}</a></li>
                            <li><i class='bx bx-envelope'></i><a :href="'mailto:'+onPage.content.address_email">{{ onPage.content.address_email }}</a></li>
                            <li><i class='bx bxs-inbox'></i><a  class="directionLtr" :href="toPhone(onPage.content.address_phone2)">{{onPage.content.address_phone2}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="footer-bottom-area">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <p>&copy; {{currentYear}} <span v-html="onPage.content.signature"></span> <a target="_blank" :href="onPage.content.sign_link_url">{{ onPage.content.sign_link_title }}</a></p>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <ul>
                            <li><a :href="onPage.content.privat_police_url">{{ onPage.content.privat_police_title }}</a></li>
                            <li><a :href="onPage.content.terms_condition_url">{{ onPage.content.terms_condition_title }}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape16"><img src="../../assets/images/shape/shape16.png" alt="image"></div>
    </footer>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'Footer',
    setup() {

        const onPage = reactive({
            content: '',
            icon:'',
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{

            let content = await loadInfo('footer?populate=icon')

            // console.log('Content: ', content.data.attributes)
            // console.log('Image: ', onPage.icon)

            onPage.content = content.data.attributes
            onPage.icon = 'https://strapi.avalon.co.il'+content.data.attributes.icon.data.attributes.url
     

        })

        const toPhone = (incomeNumber) => {

            let result = incomeNumber.replace(/[^0-9.]+/g, "")
            // let result = incomeNumber.replace(/\s/g, '');
            // result = result.replace(/[()]/g, '');
            return 'tel:'+result;
        }

        return {

            toPhone,
            onPage,
            currentYear: new Date().getFullYear(),

        };
    }
}
</script>

<style scoped>
.directionLtr{
    direction: ltr;
}
</style>