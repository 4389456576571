<template>
    <div class="contact-area pb-100">
        <div class="container" v-if="onPage.content != ''">
            <div class="section-title">
                <span class="sub-title">{{ onPage.content.subtitle }}</span>
                <h2>{{ onPage.content.title }}<span class="overlay"></span></h2>
                <p>{{ onPage.content.notification }}</p>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-image" data-tilt>
                        <img :src="'https://strapi.avalon.co.il'+onPage.content.image.data.attributes.url"  >
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" class="form-control" id="name" required :placeholder="onPage.content.name_placeholder">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" class="form-control" id="email" required :placeholder="onPage.content.email_placeholder">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" class="form-control" id="phone_number" required :placeholder="onPage.content.phone_placeholder">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" id="message" class="form-control" cols="30" rows="6" required :placeholder="onPage.content.message_placeholder"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn">{{ onPage.content.btn_title }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

// contact-content
export default {
    name: 'ContactContent',

    setup(){
    const onPage = reactive({
            content: '',
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{

            let content = await loadInfo('contact-content?populate=image')
            // console.log('Content: ', content.data.attributes)
            onPage.content = content.data.attributes

        })


        return{
            onPage,

        }
    }
}
</script>