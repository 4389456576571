<template>
    <div>
        <NavbarStyleFour />
        <PageTitle />
        <ServicesContent />
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleFour from '../Layout/NavbarStyleFour'
    import PageTitle from '../ServicesOne/PageTitle'
    import ServicesContent from '../ServicesOne/ServicesContent'
    import Footer from '../Layout/Footer'

    export default {
        name: 'ServicesOnePage',
        components: {
            NavbarStyleFour,
            PageTitle,
            ServicesContent,
            Footer,
        }
    }
</script>