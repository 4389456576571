<template>
    <div id="map" v-html="onPage.map"></div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"
//contact-map
export default {
    name: 'Map',

    setup(){

        const onPage = reactive({
            map: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9476519598093!2d-73.99185268459418!3d40.74117737932881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f81d549f%3A0xb2a39bb5cacc7da0!2s175%205th%20Ave%2C%20New%20York%2C%20NY%2010010%2C%20USA!5e0!3m2!1sen!2sbd!4v1588746137032!5m2!1sen!2sbd"></iframe>'
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{
            let map = await loadInfo('contact-map?fields[0]=map')
            // console.log('Map:', map.data.attributes.map)
            onPage.map = map.data.attributes.map
        })

        return{
            onPage
        }

    }
}
</script>