<template>
    <section class="team-area ptb-100">
        <div class="container">
            <div class="row align-items-center flex-row-reverse">

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="section-title">
                        <span class="sub-title">{{ onPage.header.block_title }}</span>
                        <h2>{{ onPage.header.title }}</h2>
                        <a :href="onPage.header.btn_url" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{ onPage.header.btn_title }} <i class="right-icon flaticon-next-button"></i></a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6" v-for="item in onPage.content" :key="item.id">
                    <div class="single-team-box">
                        <img :src="'https://strapi.avalon.co.il'+item.attributes.image.data.attributes.url" alt="team-image">

                        <div class="content">
                            <h3>{{ item.attributes.name }}</h3>
                            <span>{{ item.attributes.position }}</span>
                        </div>

                        <ul class="social-link">
                            <li><a :href="item.attributes.facebook" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a :href="item.attributes.twitter" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a :href="item.attributes.instagram" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a :href="item.attributes.linkedin" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                </div>



               

               


            </div>
        </div>
    </section>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'OurTeam',

    setup(){

        const onPage = reactive({
            header: '',
            content: '',
        })

        const { loadInfo } = connectAxios();

        onMounted(async()=>{

            let header = await loadInfo('our-team');
            onPage.header = header.data.attributes

            let content = await loadInfo('our-team-lists?populate=image');
            
            if (content.data.length > 6){
                content.data.length = 6
            }
            
            onPage.content = content.data


        })

        return {
            onPage
        }
    }
}
</script>