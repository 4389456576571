<template>
    <div class="blog-details-area bg-f9f9f9 ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="blog-details-desc">
                        <div class="article-image">
                            <img :src="'https://strapi.avalon.co.il'+content.image_main.data.attributes.url" alt="image">
                        </div>

                        <div class="article-content">
                            <div class="entry-meta">
                                <ul>
                                    <li>
                                        <i class='bx bx-folder-open'></i>
                                        <span>{{ content.category_title }}</span>
                                        <a>{{ content.category_content }}</a>
                                    </li>
                                    <li>
                                        <i class='bx bx-group'></i>
                                        <span>{{ content.view_title }}</span>
                                        {{ content.view_content }}
                                    </li>
                                    <li>
                                        <i class='bx bx-calendar'></i>
                                        <span>{{ content.updated_title }}</span>
                                        {{ content.updated_content }}
                                    </li>
                                </ul>
                            </div>

                            <h3>{{ content.content_title }}</h3>
                            <div v-html="content.content_part1"></div>
                            
                            <blockquote class="wp-block-quote" v-html="content.blockquote">
                                
                            </blockquote>

                            <div v-html="content.content_part2"></div>

                            <ul class="wp-block-gallery columns-3">

                                <li class="blocks-gallery-item" v-for="photo in content.gallery_of_three.data" :key="photo.id">
                                    <figure>
                                        <img :src="'https://strapi.avalon.co.il'+photo.attributes.url" alt="image">
                                    </figure>
                                </li>

                           
                            </ul>

                            <div v-html="content.content_part3"></div>
                           
                        </div>

                        <div class="article-footer">
                            <div class="article-tags">
                                <span><i class='bx bx-purchase-tag'></i></span>
                                <a>{{ content.tag1 }}</a>,
                                <a>{{ content.tag2 }}</a>,
                                <a>{{ content.tag3 }}</a>
                            </div>

                            <div class="article-share">
                                <ul class="social">
                                    <li><span>{{ content.share }}</span></li>
                                    <li><a :href="content.facebook" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                    <li><a :href="content.twitter" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                    <li><a :href="content.linkedin" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                    <li><a :href="content.instagram" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="article-author">
                            <div class="author-profile-header"></div>
                            <div class="author-profile">
                                <div class="author-profile-title">
                                    <img :src="'https://strapi.avalon.co.il'+content.author_image.data.attributes.url" class="shadow-sm" alt="image">
                                    <h4>{{ content.author_name }}</h4>
                                    <div v-html="content.author_description"></div>
                                   
                                </div>
                            </div>
                        </div>

                        <div class="rivate-post-navigation">
                            <div class="prev-link-wrapper" v-if="previous.slug != null">
                                <div class="info-prev-link-wrapper">
                                    <router-link :to="`/blogs/${previous.slug}`">
                                        <span class="image-prev">
                                            <img :src="'https://strapi.avalon.co.il'+previous.image_main.data.attributes.url" alt="image">
                                            <span class="post-nav-title">{{ previous.prev }}</span>
                                        </span>
    
                                        <span class="prev-link-info-wrapper">
                                            <span class="prev-title">{{ previous.content_title }}</span>
                                            <span class="meta-wrapper">
                                                <span class="date-post">{{ previous.updated_content }}</span>
                                            </span>
                                        </span>
                                    </router-link>
                                </div>
                            </div>
    
                            <div class="next-link-wrapper">
                                <div class="info-next-link-wrapper" v-if="next.slug != null">
                                    <router-link :to="`/blogs/${next.slug}`">

                                        <span class="next-link-info-wrapper">
                                            <span class="next-title">{{ next.content_title }}</span>
                                            <span class="meta-wrapper">
                                                <span class="date-post">{{ next.updated_content }}</span>
                                            </span>
                                        </span>
    
                                        <span class="image-next">
                                            <img :src="'https://strapi.avalon.co.il'+next.image_main.data.attributes.url" alt="image">
                                            <span class="post-nav-title">{{ next.next }}</span>
                                        </span>

                                    </router-link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                   <BlogSidebar />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BlogSidebar from '../Common/BlogSidebar'

export default {
    name: 'BlogDetailsContent',
        components: {
    BlogSidebar,
},
    props:{
        content: {
            type: Object,
        },
        previous: { type: Object },
        next: { type: Object }
    }
}
</script>