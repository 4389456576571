<template>
    <div class="pricing-area bg-f9f9f9 pt-100 pb-70">
        <div class="container">
            <div class="row">

                <div class="col-lg-4 col-md-6 col-sm-6" v-for="item in onPage.content" :key="item.id">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <div class="icon">
                                <i :class="item.attributes.icon"></i>
                                <div class="circles-box">
                                    <div class="circle-one"></div>
                                </div>
                            </div>
                            <h3>{{ item.attributes.title }}</h3>
                        </div>

                        <div class="pricing-features">
                            <ul>
                                <li>{{ item.attributes.option1 }}</li>
                                <li>{{ item.attributes.option2 }}</li>
                                <li>{{ item.attributes.option3 }}</li>
                                <li>{{ item.attributes.option4 }}</li>
                                <li>{{ item.attributes.option5 }}</li>
                            </ul>
                        </div>

                        <div class="price">
                            {{ item.attributes.price }}
                            <span>{{ item.attributes.period }}</span>
                        </div>

                        <a :href="item.attributes.btn_url" class="default-btn">{{ item.attributes.btn_title }}</a>

                    </div>
                </div>

                <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <div class="icon">
                                <i class="flaticon-jigsaw"></i>
                                <div class="circles-box">
                                    <div class="circle-one"></div>
                                </div>
                            </div>
                            <h3>Advance Plan</h3>
                        </div>

                        <div class="pricing-features">
                            <ul>
                                <li>15GB Bandwidth Internet</li>
                                <li>Business & Financ Analysing</li>
                                <li>30 Social Media Reviews</li>
                                <li>Customer Managemet</li>
                                <li>24/7 Support</li>
                            </ul>
                        </div>

                        <div class="price">
                            $69.99
                            <span>Per Month</span>
                        </div>

                        <a href="#" class="default-btn">Book Now</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <div class="icon">
                                <i class="flaticon-diamond"></i>
                                <div class="circles-box">
                                    <div class="circle-one"></div>
                                </div>
                            </div>
                            <h3>Premium Plan</h3>
                        </div>

                        <div class="pricing-features">
                            <ul>
                                <li>50GB Bandwidth Internet</li>
                                <li>Business & Financ Analysing</li>
                                <li>35 Social Media Reviews</li>
                                <li>Customer Managemet</li>
                                <li>24/7 Support</li>
                            </ul>
                        </div>

                        <div class="price">
                            $99.99
                            <span>Per Month</span>
                        </div>

                        <a href="#" class="default-btn">Book Now</a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'PricingContent',

    setup(){

        const onPage = reactive({
            header: '',
            content: '',
        })

        const { loadInfo } = connectAxios();

        onMounted(async()=>{

            let content = await loadInfo('our-flexible-pricing-plan-contents?sort=id')
            onPage.content = content.data

        })

        return {
            onPage

        }
    }
}
</script>