<template>
    <div>
        <NavbarStyleFour />
        <PageTitle />
        <PrivacyPolicyContent />
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleFour from '../Layout/NavbarStyleFour'
    import PageTitle from '../PrivacyPolicy/PageTitle'
    import PrivacyPolicyContent from '../PrivacyPolicy/PrivacyPolicyContent'
    import Footer from '../Layout/Footer'

    export default {
        name: 'PrivacyPolicyPage',
        components: {
            NavbarStyleFour,
            PageTitle,
            PrivacyPolicyContent,
            Footer,
        }
    }
</script>