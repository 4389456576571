<template>
    <div class="about-area">
        <div class="container-fluid">


            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="about-image" v-if="onPage.image != ''" :style="{backgroundImage: `url(${onPage.image})`}">
                        <img :src="onPage.image" >
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <div class="content">

                            <span class="sub-title">{{ onPage.content.block_title }}</span>
                            <h2>{{ onPage.content.title }}</h2>
                            <p>{{ onPage.content.description }}</p>
                            <ul class="funfacts-list">
                                <li>
                                    <div class="list">
                                        <i class="flaticon-menu-1"></i>
                                        <h3>{{ onPage.content.completed_project_number }}</h3>
                                        <p>{{ onPage.content.completed_project }}</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="list">
                                        <i class="flaticon-web-settings"></i>
                                        <h3>{{ onPage.content.working_hours_numbers}}</h3>
                                        <p>{{ onPage.content.working_hours}}</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="list">
                                        <i class="flaticon-conversation"></i>
                                        <h3>{{ onPage.content.team_members_number }}</h3>
                                        <p>{{ onPage.content.team_members }}</p>
                                    </div>
                                </li>
                            </ul>
                            <a :href="onPage.content.url" class="default-btn">{{ onPage.content.btn_title }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'AboutUs',


    setup(){

        const onPage = reactive({
            content: {},
            image: '',
        })


        const { loadInfo } = connectAxios()

        onMounted(async() => {

            let aboutUs = await loadInfo('about-us?populate=image'); // url

            onPage.content = aboutUs.data.attributes
            onPage.image = 'https://strapi.avalon.co.il'+onPage.content.image.data.attributes.url;



        })

        return {
            onPage
        }


    },

}
</script>