<template>
    <div class="faq-area pt-100 pb-70">
        <div class="container">
            <div class="row" v-if="onPage.content != ''">

                <div class="col-lg-6 col-md-6" v-for="item in onPage.content" :key="item.id">
                    <div class="faq-item">
                        <h3>{{ item.attributes.question }}</h3>
                        <div v-html="item.attributes.answer"></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
//faq-contents

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"


export default {
    name: 'FaqContent',

    setup(){

        const onPage = reactive({
            content: '',
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{

            let content = await loadInfo('faq-contents?fields[0]=question&fields[1]=answer&sort=id')
            console.log('FAQ content:', content.data)
            onPage.content = content.data

        })


        return{
            onPage
        }
    }
}
</script>