<template>
    <div class="widget-area" v-if="onPage.content != ''">
        <div class="widget widget_search">
            <h3 class="widget-title">{{ onPage.content.search }}</h3>

            <form class="search-form">
                <label>
                    <span class="screen-reader-text">Search for:</span>
                    <input type="search" class="search-field" :placeholder="onPage.content.search_placeholder">
                </label>
                <button type="submit"><i class="bx bx-search-alt"></i></button>
            </form>
        </div>

        <div class="widget widget_posts_thumb">
            <h3 class="widget-title">{{ onPage.content.popular_posts }}</h3>

            <article class="item" v-for="item in onPage.popularPosts" :key="item.key">
                <!-- <router-link :to="`/blogs/${item.attributes.slug}`" class="thumb"> -->
                <router-link :to="`/blogs/${item.attributes.slug}`" class="thumb">

                    <img :src="'https://strapi.avalon.co.il'+item.attributes.image_main.data.attributes.formats.thumbnail.url" class="fullimage cover">
                    <!-- <span class="fullimage cover bg1" role="img"></span> -->
                </router-link>
                <div class="info">
                    <span>{{item.attributes.updated_content}}</span>
                    <h4 class="title usmall"><router-link :to="`/blogs/${item.attributes.slug}`">{{item.attributes.page_title}}</router-link></h4>
                </div>

                <div class="clear"></div>
            </article>

        </div>

        <div class="widget widget_categories">
            <h3 class="widget-title">{{ onPage.content.categories }}</h3>

            <ul v-if="onPage.categories.length != 0">
                <li v-for="item in onPage.categories" :key="item">
                    <router-link :to="{ name:'search', params: { category: item.title } }">{{ item.title }} 
                        <span class="post-count">({{ item.amount}})</span>
                    </router-link>
                </li>
                <!-- <li><a href="#">Lifestyle <span class="post-count">(05)</span></a></li>
                <li><a href="#">Script <span class="post-count">(10)</span></a></li>
                <li><a href="#">Device <span class="post-count">(08)</span></a></li>
                <li><a href="#">Tips <span class="post-count">(01)</span></a></li> -->
            </ul>
        </div>

    </div>
</template>

<script>

import { useRoute } from 'vue-router'
import { onMounted, reactive } from 'vue'
import connectAxios from "../../service/getInfo"

export default {
    name: 'BlogSidebar',

    setup(){

    const route = useRoute();
    let slug = route.params.slug;

    const onPage = reactive({
        content:'',
        popularPosts: '',
        categories: [],
      
    })

    const { loadInfo } = connectAxios()

    onMounted(async() => {

        let answer = await loadInfo(`blog-sidebar`)

        onPage.content = answer.data.attributes

        let allPosts = await loadInfo('blog-detail-collections?fields[0]=view_content&fields[1]=slug&fields[2]=category_content')

        let postsIds = []

        allPosts.data.forEach((one)=>{

            if (one.attributes != slug){

                postsIds.push({
                    id: one.id,
                    view_content: one.attributes.view_content
                })

            }

        })

        function compare( b, a ) {
            if ( a.view_content < b.view_content ){
                return -1;
            }
            if ( a.view_content > b.view_content ){
                return 1;
            }
            return 0;
        }

        function compare2( b, a ) {
            if ( a.attributes.view_content < b.attributes.view_content ){
                return -1;
            }
            if ( a.attributes.view_content > b.attributes.view_content ){
                return 1;
            }
            return 0;
        }

        postsIds.sort(compare)

        // let allPostsIds = await loadInfo(`blog-detail-collections?&fields[0]=id`)
        
        if (allPosts.data.length > 3){

            postsIds.length = 3

            let threePosts = await loadInfo(`blog-detail-collections?filters[id][$in][0]=${postsIds[0].id}&filters[id][$in][1]=${postsIds[1].id}&filters[id][$in][2]=${postsIds[2].id}&fields[0]=slug&fields[1]=page_title&fields[2]=updated_content&fields[3]=view_content&populate=image_main`)

            threePosts.data.sort(compare2)

            onPage.popularPosts = threePosts.data

        }
        

        loadAndCalcAllCategories(allPosts);

    })

    const loadAndCalcAllCategories = (allPosts) => {

        // console.log('All Posts:', allPosts.data)

        allPosts.data.forEach((one) => {

            let item = {
                    'amount': 1,
                    'title': one.attributes.category_content
                }
            
            let sovpadenie = false;
            for (let i = 0; i < onPage.categories.length; i++){
                
                if (onPage.categories[i].title == one.attributes.category_content){
                    sovpadenie = true;
                    onPage.categories[i].amount++
                    return;
                }

            }
            if (sovpadenie == false){
                onPage.categories.push(item)
            }
           
        })

    }

    return{
        onPage
    }
    }
}
</script>