<template>
    <div>
        <NavbarStyleFour />
        <PageTitle />
        <CreativeSolutions />
        <CoreBusiness />
        <GetFreeAnalysis />
        <OurFlexiblePricingPlan />
        <StartYourProjectWithUs />
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleFour from '../Layout/NavbarStyleFour'
    import PageTitle from '../ServicesDetails/PageTitle'
    import CreativeSolutions from '../ServicesDetails/CreativeSolutions'
    import CoreBusiness from '../ServicesDetails/CoreBusiness'
    import GetFreeAnalysis from '../ServicesDetails/GetFreeAnalysis'
    import OurFlexiblePricingPlan from '../Common/OurFlexiblePricingPlan'
    import StartYourProjectWithUs from '../Common/StartYourProjectWithUs'
    import Footer from '../Layout/Footer'

    export default {
        name: 'ServicesDetailsPage',
        components: {
            NavbarStyleFour,
            PageTitle,
            CreativeSolutions,
            CoreBusiness,
            GetFreeAnalysis,
            OurFlexiblePricingPlan,
            StartYourProjectWithUs,
            Footer,
        }
    }
</script>