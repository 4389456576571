
import axios from 'axios';

export default function connectAxios() {

    const loadInfo = async (url) => {
        
        try{
          // let response = await axios.get(`http://localhost:1337/api/${url}`);
          let response = await axios.get(`https://strapi.avalon.co.il/api/${url}`);

          if(response.status == 200){
            return response.data
          } else {
            console.log('Something went wrong! ', response)
          }
          
          
        }  catch(e){

          console.log('Error: ', e)
        }
      
    }

    return {
     
    loadInfo,
  
    };

}