<template>
    <div class="featured-services-area ptb-100">
        <div class="container">
            <div class="row" v-if="onPage.content != ''">
                <div class="col-lg-4 col-md-6 col-sm-6" v-for="item in onPage.content" :key="item.id">
                    <div class="single-featured-services-box">
                        <div class="icon">
                            <i :class="item.attributes.icon"></i>
                            <div class="circles-box">
                                <div class="circle-one"></div>
                                <div class="circle-two"></div>
                            </div>
                        </div>
                        <h3><router-link to="/services-details">{{ item.attributes.title }}</router-link></h3>
                        <div v-html="item.attributes.description"></div>
                        <router-link to="/services-details" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{ item.attributes.btn_title }} <i class="right-icon flaticon-next-button"></i></router-link>
                    </div>
                </div>

                <!-- <div class="col-lg-12 col-md-12">
                    <div class="pagination-area text-center">
                        <a href="#" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                        <span class="page-numbers current" aria-current="page">1</span>
                        <a href="#" class="page-numbers">2</a>
                        <a href="#" class="page-numbers">3</a>
                        <a href="#" class="page-numbers">4</a>
                        <a href="#" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'ServicesContent',

    setup(){

        const onPage = reactive({
            content :'',
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{

            let content = await loadInfo('services-content-services')
            onPage.content = content.data

        })


        return {
            onPage
        }
    }
}
</script>