<template>
    <div class="contact-info-area pt-100 pb-70">

        <div class="container" v-if="onPage.content != ''">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="contact-info-box">
                        <div class="back-icon">
                            <i class='bx bx-map'></i>
                        </div>
                        <div class="icon">
                            <i class='bx bx-map'></i>
                        </div>
                        <h3>{{ onPage.content.contact_address_title }}</h3>
                        <div v-html="onPage.content.contact_address"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="contact-info-box">
                        <div class="back-icon">
                            <i class='bx bx-phone-call'></i>
                        </div>
                        <div class="icon">
                            <i class='bx bx-phone-call'></i>
                        </div>
                        <h3>{{ onPage.content.contact_title }}</h3>
                        <p>{{ onPage.content.contact_phone_title }} <a :href="toPhone(onPage.content.contact_phone)">{{ onPage.content.contact_phone }}</a></p>
                        <p>{{ onPage.content.contact_email_title }} <a :href="'mailto:'+onPage.content.contact_email">{{ onPage.content.contact_email }}</a></p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="contact-info-box">
                        <div class="back-icon">
                            <i class='bx bx-time-five'></i>
                        </div>
                        <div class="icon">
                            <i class='bx bx-time-five'></i>
                        </div>
                        <h3>{{ onPage.content.hours_title }}</h3>
                        <div v-html="onPage.content.hours_content"></div>
                        
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'ContactInfo',

    setup(){

        const onPage = reactive({
            content: '',
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{

            let content = await loadInfo('contact-info')
            onPage.content = content.data.attributes

        })

        const toPhone = (incomeNumber) => {

            let result = incomeNumber.replace(/[^0-9.]+/g, "")

            return 'tel:'+result;

        }

        return{
            onPage,

            toPhone

        }
    }

}
</script>