<template>
    <div class="subscribe-area ptb-100">
        <div class="container">
            <div class="subscribe-content">
                <span class="sub-title">{{ onPage.content.block_title }}</span>
                <h2>{{onPage.content.title}}</h2>
                <p>{{ onPage.content.description }}</p>
                <form class="newsletter-form" data-toggle="validator">
                    <input type="text" class="input-newsletter" :placeholder="onPage.content.placeholder" name="EMAIL" required>
                    <button type="submit" class="default-btn">{{ onPage.content.btn_title }}</button>
                </form>
            </div>
        </div>

        <div class="shape9"><img src="../../assets/images/shape/shape9.png" alt="image"></div>
        <div class="shape10"><img src="../../assets/images/shape/shape10.png" alt="image"></div>
        <div class="shape11"><img src="../../assets/images/shape/shape11.png" alt="image"></div>
        <div class="shape12"><img src="../../assets/images/shape/shape12.png" alt="image"></div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'OurNewsletter',

    setup(){

        const onPage = reactive({
            content: '',
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{

            let content = await loadInfo('our-newsletter')

            onPage.content = content.data.attributes
            // console.log('Content: ', content.data.attributes)
        })

        return{
            onPage
        }

    }
}
</script>