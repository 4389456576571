<template>
    <div>
        <div :class="['navbar-area navbar-color-white', {'is-sticky': isSticky}]">
            <div class="rivate-nav">
                <div class="container">
                    <nav class="navbar navbar-expand-lg navbar-light" v-if="onPage.content != ''">
                        
                        <div class="others-option d-flex align-items-center">
                 
                            <div class="option-item">
                                <div class="search-box" v-on:click="isSearchMethod(isSearch)">
                                    <i class="flaticon-search"></i>
                                </div>
                            </div>

                            <div class="search-overlay" v-if="isSearch">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <div class="search-overlay-layer"></div>
                                        <div class="search-overlay-layer"></div>
                                        <div class="search-overlay-layer"></div>
                                        
                                        <div class="search-overlay-close" v-on:click="isSearchMethod(isSearch)">
                                            <span class="search-overlay-close-line"></span>
                                            <span class="search-overlay-close-line"></span>
                                        </div>

                                        <div class="search-overlay-form">
                                            <form>
                                                <input type="text" class="input-search" :placeholder="onPage.content.search_placeholder">
                                                <button type="submit"><i class="flaticon-search"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="option-item">
                                <div 
                                    class="side-menu-btn"
                                    v-on:click="isMenuMethod(isMenu)"
                                >
                                    <i class="flaticon-menu"></i>
                                </div>
                            </div>
                        </div>
                        
                        <div 
                            class="navbar-toggler"
                            @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                            v-bind:class="{ 'active': button_active_state }"
                            v-on:click="button_active_state = !button_active_state"
                        >
                            <span class="icon-bar top-bar"></span>
                            <span class="icon-bar middle-bar"></span>
                            <span class="icon-bar bottom-bar"></span>
                        </div>
                        
                        <div 
                            class="collapse navbar-collapse" 
                            :class="{ show: active }"
                        >
                            <ul class="navbar-nav">

                                <li class="nav-item"><router-link to="/" class="nav-link">{{ onPage.content.home }}</router-link></li>

                                <li class="nav-item"><router-link to="/about" class="nav-link">{{ onPage.content.about }}</router-link></li>


                                <li class="nav-item"><router-link to="/services" class="nav-link">{{ onPage.content.service }}</router-link></li>

                                <li class="nav-item"><a href="#" class="nav-link">{{ onPage.content.pages }} <i class='bx bx-chevron-down'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><router-link to="/pricing" class="nav-link">{{ onPage.content.pricing }}</router-link></li>

                                        <li class="nav-item"><router-link to="/faq" class="nav-link">{{ onPage.content.faq }}</router-link></li>

                                        <li class="nav-item"><router-link to="/privacy-policy" class="nav-link">{{ onPage.content.privacy_policy }}</router-link></li>
        
                                        <li class="nav-item"><router-link to="/terms-of-service" class="nav-link">{{ onPage.content.terms_of_service }}</router-link></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><router-link to="/portfolio" class="nav-link">{{ onPage.content.portfolio }}</router-link></li>

                                <li class="nav-item"><router-link to="/blog" class="nav-link">{{ onPage.content.blog }}</router-link></li>

                                <li class="nav-item"><router-link to="/contact" class="nav-link">{{ onPage.content.contact }}</router-link></li>
                            </ul>
                        </div>

                        <router-link class="navbar-brand" to="/">
                            <img :src="'https://strapi.avalon.co.il'+onPage.content.icon_white.data.attributes.url"  alt="logo">
                        </router-link>
                        
                    </nav>
                </div>
            </div>
        </div>
    
        <div 
            class="sidebar-modal"
            v-if="isMenu"
        >
            <div class="sidebar-modal-inner">
                <div 
                    class="close"
                    v-on:click="isMenuMethod(isMenu)"
                >
                    x
                </div>
                <div class="modal-sidebar-content">
                    <div class="logo">
                        <router-link to="/" class="d-inline-block">
                            <img :src="'https://strapi.avalon.co.il'+onPage.content.icon.data.attributes.url" alt="image">
                        </router-link>
                    </div>

                    <div class="instagram-list">
                        <div class="row justify-content-center">

                            <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                <div class="box">
                                    <img :src="'https://strapi.avalon.co.il'+onPage.content.popup_photo.data.attributes.url" alt="image">
                                    <i class='bx bxl-instagram'></i>
                                    <a :href="onPage.content.popup_insta" target="_blank" class="link-btn"></a>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                    
                    <div class="sidebar-contact-info directionLtr">
                        <h2>
                            <a :href="toPhone(onPage.content.popup_phone)">{{ onPage.content.popup_phone }}</a>
                            <span>OR</span>
                            <a :href="'mailto:'+onPage.content.popup_email">{{ onPage.content.popup_email }}</a>
                        </h2>
                    </div>

                    <ul class="social-list">
                        <li><span>{{ onPage.content.popup_follow_us }}</span></li>
                        <li v-if="onPage.content.popup_facebook"><a :href="onPage.content.popup_facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li v-if="onPage.content.popup_twitter"><a :href="onPage.content.popup_twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li v-if="onPage.content.popup_youtube"><a :href="onPage.content.popup_youtube" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        <li v-if="onPage.content.popup_twich"><a :href="onPage.content.popup_twich" target="_blank"><i class='bx bxl-twitch'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'NavbarStyleFour',
    data(){

        const onPage = reactive({
            content: '',
        })

        return {
            isSticky: false,
            isSearch: false,
            active: false,
            button_active_state: false,
            isMenu: false,
            onPage
        }
    },
    async mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })

        const { loadInfo } = connectAxios();

        let content = await loadInfo('navbar?populate=icon&populate=popup_photo&populate=icon_white')
        // console.log('Navbar: ', content.data.attributes)
        this.onPage.content = content.data.attributes
    },
    methods: {
        isSearchMethod(isSearch){
            return this.isSearch = !isSearch
        },
        isMenuMethod(isMenu){
            return this.isMenu = !isMenu
        },
        toPhone(incomeNumber){
            let result = incomeNumber.replace(/[^0-9.]+/g, "")
            // let result = incomeNumber.replace(/\s/g, '');
            // result = result.replace(/[()]/g, '');
            return 'tel:'+result;
        },
    }
}
</script>

<style scoped>
.directionLtr{
    direction: ltr;
}
</style>