<template>

<section class="search_content bg-f9f9f9 ptb-100">
        <div class="container">
            <div class="row" >

                <div class="col-lg-12 col-md-8" >
                    <h3> 
                        {{ onPage.answer }} 
                    </h3>
                    <div class="result_description"> {{ onPage.description }} </div>
                </div>

            </div>

            <div class="row" v-if="onPage.result.length != 0">

                <div class="col-lg-12 col-md-8" v-for="item in onPage.result" :key="item.id">

                    <router-link :to="'/'+item.url">{{ item.title }}</router-link>

                </div>


            </div>
        </div>
    </section>

</template>

<script>

import { useRoute } from 'vue-router';
import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"



export default {
    name: 'SearchContent',

    setup(){
        const route = useRoute();
        const qs = require('qs');
        const strapiQuery = {
            filters: {}
        }
        
        // console.log('Route:', route.params)
        let category = route.params.category
        let text = route.params.text

        // console.log('Category:', category)
        // console.log('Text:', text)


        const onPage = reactive({
            category: category,
            text: text,
            answer: 'Nothing is found',
            description: '',
            result: [],
            categoryTitle: 'blog(category): ',
            blogTitle: 'Blog:',
            portfolioTitle: 'Portfolio',
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{

            // console.log('Category:', category)
            onPage.answer = 'Search in progress';


            if (category != ''){ 

               await serchCategory(category)

            } else if (text != ''){ 

                // console.log('Обрабатываю общий запрос') 
                await searchText(text)

            } 
           


            if (onPage.result.length == 0){

                onPage.answer = 'Nothing is found'

            } else if (onPage.result.length > 0) {

                onPage.answer = `Found (${onPage.result.length})`;
                if (category != '') { onPage.description = `blog category:  ${category} ` }

            }
            

        })

        const searchText = async(searchText) =>{
            onPage.result.length = 0;

            // in detail
            // strapiQuery['filters'] = {
            //     $or: [
            //             {   
            //                 'page_title': {
            //                         $containsi: searchText
            //                         },
            //             },
            //             {   
            //                 'content_part1': {
            //                         $containsi: searchText
            //                         }
            //             },
            //         ],
                   
            // }

            

            // работает но находит соответствие только в начале строки(русский текст - иврит нужно тестировать) !!!
            let searchFields = ['page_title', 'content_part1','blockquote','content_part2','content_part3'];
            strapiQuery['filters']['$or'] = searchFields.map((field) => {
                const searchField = {}
                searchField[field] = { $containsi: searchText }  
                return searchField
            })

            let strapiQueryStr = qs.stringify(strapiQuery, { encodeValuesOnly: true })
            let answer = await loadInfo(`blog-detail-collections?${strapiQueryStr}&fields[0]=slug&fields[1]=page_title`)
            // console.log('Blogs', answer.data)

            if (answer.data.length > 0){

                answer.data.forEach(element => {
                    onPage.result.push({
                            id: element.id,
                            url: `blogs/${element.attributes.slug}`,
                            title: element.attributes.page_title
                        })
                })

                onPage.description = `blog(${answer.data.length})`
            }



            searchFields = ['title', 'content_part1','content_part2','content_result'];
            strapiQuery['filters']['$or'] = searchFields.map((field) => {
                const searchField = {}
                searchField[field] = { $containsi: searchText }  
                return searchField
            })

            strapiQueryStr = qs.stringify(strapiQuery, { encodeValuesOnly: true })
            let portfolio = await loadInfo(`portfolio-collections?${strapiQueryStr}&fields[0]=slug&fields[1]=title`)
            // console.log('Portfolio', portfolio.data)

            if (portfolio.data.length > 0){

                portfolio.data.forEach(element => {
                    onPage.result.push({
                            id: element.id,
                            url: `portfolio/${element.attributes.slug}`,
                            title: element.attributes.title
                        })
                })
                onPage.description = onPage.description + ` portfolio(${portfolio.data.length})`
            }

        }

        const serchCategory = async(category) => {
            onPage.result.length = 0;

            const categorySearch = await loadInfo(`blog-detail-collections?filters[category_content][$eqi]=${category}&fields[0]=slug&fields[1]=page_title`)
            // console.log('CategorySearch: ', categorySearch.data)

            if (categorySearch.data.length > 0){

                categorySearch.data.forEach(element => {
                    onPage.result.push({
                        id: element.id,
                        url: `blogs/${element.attributes.slug}`,
                        title: element.attributes.page_title
                    })
                });

            }

            // onPage.result = categorySearch.data;

        }



        return {
            onPage
        }
    }
}

</script>


<style scoped>
.result_description{
    font-size: 12px;
    margin-bottom: 20px;
}

</style>