<template>
    <div class="portfolio-area pb-70">
        <div class="container">
            <div class="section-title">
                <h2>{{ title }}</h2>
            </div>
           
            <div class="row" v-if="projects.length > 0">

                <div class="col-lg-4 col-md-6" v-for="item in projects" :key="item.slug">
                    <div class="single-portfolio-item">
                        <router-link :to="`/portfolio/${item.slug}`" class="image d-block">
                            <img :src="'https://strapi.avalon.co.il'+item.main_image.data.attributes.url" alt="image">
                        </router-link>

                        <div class="content">
                            <h3><router-link :to="`/portfolio/${item.slug}`">{{ item.title }}</router-link></h3>
                            <router-link :to="`/portfolio/${item.slug}`" class="link-btn"><i class="flaticon-next-button"></i></router-link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MoreProjects',
    props: {
        title: { type: String },
        projects: { type: Array }
    }
}
</script>