<template>
    <div>
        <NavbarStyleFour />
        <PageTitle />
        <TermsOfServiceContent />
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleFour from '../Layout/NavbarStyleFour'
    import PageTitle from '../TermsOfService/PageTitle'
    import TermsOfServiceContent from '../TermsOfService/TermsOfServiceContent'
    import Footer from '../Layout/Footer'

    export default {
        name: 'TermsOfServicePage',
        components: {
            NavbarStyleFour,
            PageTitle,
            TermsOfServiceContent,
            Footer,
        }
    }
</script> 