<template>
    <div>
        <NavbarStyleFour />

        <template v-if="onPage.title != ''">
            <PageTitle :title="onPage.title" />
            <PortfolioDetailsContent :content="onPage.content" />
            <MoreProjects :title="onPage.more_projects_title" :projects="onPage.more_projects" />
        </template>
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleFour from '../Layout/NavbarStyleFour'
    import PageTitle from '../PortfolioDetails/PageTitle'
    import PortfolioDetailsContent from '../PortfolioDetails/PortfolioDetailsContent'
    import MoreProjects from '../PortfolioDetails/MoreProjects'
    import Footer from '../Layout/Footer'

    import { useRoute, useRouter } from "vue-router"
    import { onMounted, reactive, watch } from 'vue'
    import connectAxios from "../../service/getInfo"


    export default {
        name: 'PortfolioDetailsPage',
        components: {
            NavbarStyleFour,
            PageTitle,
            PortfolioDetailsContent,
            MoreProjects,
            Footer,
        },

        setup(){

            const route = useRoute()
            const router = useRouter()
            let slug = route.params.slug

            const onPage = reactive({
                content:'',
                title: '',
                more_projects_title: '',
                more_projects: [],

            })

            watch(route, (to) => {
                
                // console.log('Slug: ',to.params.slug)
                slug = to.params.slug

                if (slug != null){
                    LoadData()
                }

                
            })

            const { loadInfo } = connectAxios()

            onMounted(async() => {
                
                await LoadData();
                
            })

            const LoadData = async () => {

                let answer = await loadInfo(`portfolio-collections?filters[slug][$eq]=${slug}&populate=main_image&populate=second_image&populate=fact_image`)

                // console.log('Answer', answer.data)

                if (answer.data.length > 0){

                    onPage.content = answer.data[0].attributes,
                    onPage.title = answer.data[0].attributes.title
                    onPage.more_projects_title = answer.data[0].attributes.projects_you_may_like

                    const projects = await loadInfo('portfolio-collections?populate=main_image&fields[0]=slug&fields[1]=title')

                    let added = 0

                    onPage.more_projects.length = 0

                    for (let i = 0; i < projects.data.length; i++){

                        if (projects.data[i].attributes.slug != slug ){
                            added+=1;
                            onPage.more_projects.push(projects.data[i].attributes)
                        }

                        console.log('Projects:', onPage.more_projects)

                        if (added == 3){
                            return;
                        }

                    }
                    

                } else {
                    router.push({path:'/error-404'})
                }

            }

            return{
                onPage
            }
        }

    }
</script>