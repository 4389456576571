<template>
    <div class="services-area bg-f9f9f9 pt-100 pb-70">
        <div class="container" v-if="onPage.content != ''">
            <div class="section-title">
                <span class="sub-title">{{ onPage.content.subtitle }}</span>
                <h2>{{ onPage.content.title }}</h2>
                <div v-html="onPage.content.description"></div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i :class="onPage.content.block1_icon"></i>
                            <div class="circles-box">
                                <div class="circle-one"></div>
                                <div class="circle-two"></div>
                            </div>
                        </div>
                        <h3>{{ onPage.content.block1_title }}</h3>
                        <div v-html="onPage.content.block1_description"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i :class="onPage.content.block2_icon"></i>
                            <div class="circles-box">
                                <div class="circle-one"></div>
                                <div class="circle-two"></div>
                            </div>
                        </div>
                        <h3>{{ onPage.content.block2_title }}</h3>
                        <div v-html="onPage.content.block2_description"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i :class="onPage.content.block3_icon"></i>
                            <div class="circles-box">
                                <div class="circle-one"></div>
                                <div class="circle-two"></div>
                            </div>
                        </div>
                        <h3>{{ onPage.content.block3_title }}</h3>
                        <div v-html="onPage.content.block3_description"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i :class="onPage.content.block4_icon"></i>
                            <div class="circles-box">
                                <div class="circle-one"></div>
                                <div class="circle-two"></div>
                            </div>
                        </div>
                        <h3>{{ onPage.content.block4_title }}</h3>
                        <div v-html="onPage.content.block4_description"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i :class="onPage.content.block5_icon"></i>
                            <div class="circles-box">
                                <div class="circle-one"></div>
                                <div class="circle-two"></div>
                            </div>
                        </div>
                        <h3>{{ onPage.content.block5_title }}</h3>
                        <div v-html="onPage.content.block5_description"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i :class="onPage.content.block6_icon"></i>
                            <div class="circles-box">
                                <div class="circle-one"></div>
                                <div class="circle-two"></div>
                            </div>
                        </div>
                        <h3>{{ onPage.content.block6_title }}</h3>
                        <div v-html="onPage.content.block6_description"></div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'CoreBusiness',

    setup(){

        const onPage = reactive({
            content: ''
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{

            let content = await loadInfo('core-busines-content')
            // console.log('CoreBusiness:', content.data.attributes)
            onPage.content = content.data.attributes

        })


        return{
            onPage
        }
    }
}
</script>