<template>
    <div>
        <NavbarStyleFour />
        <PageTitle />
        <PortfolioColumnsTwoContent />
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleFour from '../Layout/NavbarStyleFour'
    import PageTitle from '../PortfolioOne/PageTitle'
    import PortfolioColumnsTwoContent from '../PortfolioOne/PortfolioColumnsTwoContent'
    import Footer from '../Layout/Footer'

    export default {
        name: 'PortfolioOnePage',
        components: {
            NavbarStyleFour,
            PageTitle,
            PortfolioColumnsTwoContent,
            Footer,
        }
    }
</script>