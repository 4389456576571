<template>
    <div class="partner-area bg-f9f9f9 ptb-70">
        <div class="container">
            <div class="row align-items-center" v-if="onPage.content != ''">

                <div class="col-lg-2 col-6 col-sm-4 col-md-4" v-for="item in onPage.content" :key="item.id">
                    <div class="single-partner-item">
                        <img :src="'https://strapi.avalon.co.il'+item.attributes.image.data.attributes.url"  >
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'Partner',

    setup(){

        const onPage = reactive({
            content: '',

        })


        const { loadInfo } = connectAxios()

        onMounted(async() => {

            let content = await loadInfo('partners?populate=image&sort=id')
            // console.log('Partners:', content.data)  


            onPage.content = content.data


        })

        return {
            onPage

        }


    }
}
</script>