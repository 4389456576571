<template>
    <div v-if="onPage.title == ''">
        <PreloaderVue></PreloaderVue>
    </div>
    <div v-else>
        <NavbarStyleFour />
            <PageTitle :title="onPage.title" />
            <BlogDetailsContent :content="onPage.content" :previous="onPage.previous_post" :next="onPage.next_post"/>
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleFour from '../Layout/NavbarStyleFour'
    import PageTitle from '../BlogDetailsOne/PageTitle'
    import BlogDetailsContent from '../BlogDetailsOne/BlogDetailsContent'
    import Footer from '../Layout/Footer'
    import PreloaderVue from '../Layout/Preloader.vue'

    import { useRoute, useRouter } from "vue-router"
    import { onMounted, reactive, watch } from 'vue'
    import connectAxios from "../../service/getInfo"

    export default {
        name: 'BlogDetailsOnePage',
        components: {
            NavbarStyleFour,
            PageTitle,
            BlogDetailsContent,
            Footer,
            PreloaderVue
        },

        setup(){

            const route = useRoute()
            const router = useRouter()
            let slug = route.params.slug

            const onPage = reactive({
                content:'',
                title: '',
                previous_post: {},
                next_post: {},
            })

            watch(route, (to) => {
                
                slug = to.params.slug

                if (slug != null){
                    LoadData()
                }

                
            })

            const { loadInfo } = connectAxios()

            onMounted(async() => {
                
                await LoadData();
                
            })

            const LoadData = async () => {

                let answer = await loadInfo(`blog-detail-collections?filters[slug][$eq]=${slug}&populate=image_main&populate=gallery_of_three&populate=author_image`)

                onPage. previous_post = {},
                onPage.next_post = {}

                if (answer.data.length > 0){

                    onPage.content = answer.data[0].attributes,
                    onPage.title = answer.data[0].attributes.page_title

                    let currentPostId = answer.data[0].id

                    const sosedi = await loadInfo('blog-detail-collections?fields[0]=updated_content&fields[1]=content_title&fields[2]=prev&fields[3]=next&fields[4]=slug&populate=image_main')

                    for (let i=0; i < sosedi.data.length; i++){

                        if (currentPostId == sosedi.data[i].id){

                            if (i > 0){
                                onPage.previous_post = sosedi.data[i-1].attributes;
                            }
                            if (i+1 < sosedi.data.length){
                                onPage.next_post = sosedi.data[i+1].attributes;
                            }

                            return;
                        }
                    }

                    

                } else {
                    router.push({path:'/error-404'})
                }

            }

            

            return{
                onPage
            }
        }
    }
</script>