import { createWebHistory, createRouter } from "vue-router";

// All Pages
import HomeDemoOne from "../components/Pages/HomeDemoOne";

import AboutOnePage from "../components/Pages/AboutOnePage";

import ServicesOnePage from "../components/Pages/ServicesOnePage";

import TeamPageOne from "../components/Pages/TeamPageOne";

import SearchPage from "../components/Pages/SearchPage"

import ServicesDetailsPage from "../components/Pages/ServicesDetailsPage";
import PricingPage from "../components/Pages/PricingPage";

import FaqPage from "../components/Pages/FaqPage";
import ErrorPage from "../components/Pages/ErrorPage";

import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import TermsOfServicePage from "../components/Pages/TermsOfServicePage";
import PortfolioOnePage from "../components/Pages/PortfolioOnePage";

import PortfolioDetailsPage from "../components/Pages/PortfolioDetailsPage";
import BlogOnePage from "../components/Pages/BlogOnePage";
import BlogDetailsOnePage from "../components/Pages/BlogDetailsOnePage";

import ContactUsPage from "../components/Pages/ContactUsPage";

const routes = [
  { path: "/", component: HomeDemoOne },

  { path: "/about", component: AboutOnePage },

  { path: "/team", component: TeamPageOne }, //????

  { path: "/services", component: ServicesOnePage },

  { name:'search', path: "/search/:text?/:category?", component: SearchPage },

  { path: "/services-details", component: ServicesDetailsPage },
  { path: "/pricing", component: PricingPage },
 
  { path: "/faq", component: FaqPage },
  { path: "/privacy-policy", component: PrivacyPolicyPage },
  { path: "/terms-of-service", component: TermsOfServicePage },

  { path: "/portfolio", component: PortfolioOnePage }, 
  { path: "/portfolio/:slug", component: PortfolioDetailsPage },


  { path: "/blog", component: BlogOnePage }, 
  { path: "/blogs/:slug", component: BlogDetailsOnePage }, // каждый блок должен иметь поле slug - ссылка

  { path: "/contact", component: ContactUsPage },

  { path: "/error-404", component: ErrorPage },
  { path: "/:catchAll(.*)", redirect: "/error-404" },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
