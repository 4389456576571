<template>
    <div>
        <NavbarStyleFour />
        <PageTitle />
        <AboutUs class="ptb-100" />
        <HistoryBegins />
        <OurTeam />
        <Partner />
        <OurClientsAreSaying />
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleFour from '../Layout/NavbarStyleFour'
    import PageTitle from '../AboutOne/PageTitle'
    import AboutUs from '../Common/AboutUs'
    import HistoryBegins from '../Common/HistoryBegins'
    import OurTeam from '../Common/OurTeam'
    import Partner from '../Common/Partner'
    import OurClientsAreSaying from '../Common/OurClientsAreSaying'
    import Footer from '../Layout/Footer'

    export default {
        name: 'AboutOnePage',
        components: {
            NavbarStyleFour,
            PageTitle,
            AboutUs,
            HistoryBegins,
            OurTeam,
            Partner,
            OurClientsAreSaying,
            Footer,
        }
    }
</script>