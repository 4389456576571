<template>
    <div class="history-area ptb-100 bg-f9f9f9">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">{{ onPage.header.block_title }}</span>
                <h2>{{ onPage.header.title }}</h2>
            </div>

            <ol class="timeline history-timeline" v-if="onPage.content != ''">
                <li class="timeline-block" v-for="item in onPage.content" :key="item.id">
                    <div class="timeline-date" v-html="item.attributes.time">
                      
                    </div>

                    <div class="timeline-icon">
                        <span class="dot-badge"></span>
                    </div>

                    <div class="timeline-content">
                        <div class="row align-items-center">
                            <div class="col-lg-7 col-md-12">
                                <div class="content">
                                    <h3>{{ item.attributes.title }}</h3>
                                    <div v-html="item.attributes.description"></div>
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="image">
                                    <img :src="'https://strapi.avalon.co.il'+item.attributes.image.data.attributes.url" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

            </ol>
        </div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'HistoryBegins',

    setup(){

        const onPage = reactive({
            header: {
                block_title: 'Our History',
                title: 'History Begins In 2010',
            },
            content: '',
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{

            let header = await loadInfo('history-begin-title?fields[0]=block_title&fields[1]=title')
            onPage.header = header.data.attributes

            let content = await loadInfo('history-begin-contents?sort=id&populate=image')
            // console.log('History content: ',content.data)
            onPage.content = content.data
            

        })


        return {
            onPage
        }

    }
}
</script>