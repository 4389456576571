<template>
    <div class="portfolio-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="portfolio-details-image">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <img :src="'https://strapi.avalon.co.il'+content.main_image.data.attributes.url" alt="image">
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <img :src="'https://strapi.avalon.co.il'+content.second_image.data.attributes.url" alt="image">
                            </div>
                        </div>
                    </div>

                    <div class="portfolio-details-desc">
                        <span class="sub-title">{{ content.subtitle }}</span>
                        <h3>{{ content.content_title }}</h3>
                        <div v-html="content.content_part1"></div>

                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6">
                                <div class="image">
                                    <img :src="'https://strapi.avalon.co.il'+content.fact_image.data.attributes.url" alt="image">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="content">
                                    <h3>{{ content.fact_title }}</h3>
                                    <ul>
                                        <li>{{ content.fact1 }}</li>
                                        <li>{{ content.fact2 }}</li>
                                        <li>{{ content.fact3 }}</li>
                                        <li>{{ content.fact4 }}</li>
                                        <li>{{ content.fact5 }}</li>
                                        <li>{{ content.fact6 }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div v-html="content.content_part2"></div>
                        
                        <h3>{{ content.result }}</h3>

                        <div v-html="content.content_result"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="portfolio-sidebar-sticky">
                        <div class="portfolio-details-info">
                            <ul>
                                <li>
                                    <div class="icon">
                                        <i class='bx bx-user-pin'></i>
                                    </div>
                                    <span>{{ content.client }}</span>
                                    <div v-html="content.client_description"></div>
                                    
                                </li>
                                <li>
                                    <div class="icon">
                                        <i class='bx bx-map'></i>
                                    </div>
                                    <span>{{ content.location }}</span>
                                    <div v-html="content.location_description"></div>
                                    
                                </li>
                                <li>
                                    <div class="icon">
                                        <i class='bx bx-purchase-tag'></i>
                                    </div>
                                    <span>{{ content.technology }}</span>
                                    <div v-html="content.technology_description"></div>
                                </li>
                                <li>
                                    <div class="icon">
                                        <i class='bx bx-check'></i>
                                    </div>
                                    <span>{{ content.completed }}</span>
                                    {{ content.complited_content }}
                                </li>
                                <li>
                                    <div class="icon">
                                        <i class='bx bx-globe'></i>
                                    </div>
                                    <span>{{ content.website }}</span>
                                    <div v-html="content.website_content"></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PortfolioDetailsContent',
    props:{
        content: {
            type: Object,
        },
    }

}
</script>