<template>
    <div>
        <ErrorContent />
    </div>
</template>

<script>
    import ErrorContent from '../Error/ErrorContent'

    export default {
        name: 'ErrorPage',
        components: {
            ErrorContent,
        }
    }
</script>