<template>
    <div>
        <NavbarStyleFour />
        <PageTitle />
        <BlogOneContent />
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleFour from '../Layout/NavbarStyleFour'
    import PageTitle from '../BlogOne/PageTitle'
    import BlogOneContent from '../BlogOne/BlogOneContent'
    import Footer from '../Layout/Footer'

    export default {
        name: 'BlogOnePage',
        components: {
            NavbarStyleFour,
            PageTitle,
            BlogOneContent,
            Footer,
        }
    }
</script> PortfolioNoSpacePage