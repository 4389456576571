<template>
    <div class="overview-area ptb-100">
        <div class="container" v-if="onPage.content != ''">
            <div class="overview-box">
                <div class="overview-content">
                    <div class="content">
                        <span class="sub-title">{{ onPage.content.subtitle1 }}</span>
                        <h2>{{ onPage.content.title1 }}</h2>
                        <div v-html="onPage.content.description1"></div>
                        <div class="features-text" v-html="onPage.content.features_text1"></div>
                        <div class="features-text" v-html="onPage.content.features_text2"></div>
                    </div>
                </div>

                <div class="overview-image">
                    <div class="image">
                        <img :src="'https://strapi.avalon.co.il'+onPage.content.image1.data.attributes.url" alt="image">
                    </div>
                </div>
            </div>

            <div class="overview-box">
                <div class="overview-image">
                    <div class="image">
                        <img :src="'https://strapi.avalon.co.il'+onPage.content.image2.data.attributes.url" alt="image">
                    </div>
                </div>
                
                <div class="overview-content">
                    <div class="content right-content">
                        <span class="sub-title">{{ onPage.content.small_title2}}</span>
                        <h2>{{ onPage.content.title2}}</h2>
                        <div v-html="onPage.content.description2"></div>
                        <ul class="features-list">
                            <li><span><i class='bx bx-check'></i>{{ onPage.content.option1}}</span></li>
                            <li><span><i class='bx bx-check'></i>{{ onPage.content.option2}}</span></li>
                            <li><span><i class='bx bx-check'></i>{{ onPage.content.option3}}</span></li>
                            <li><span><i class='bx bx-check'></i>{{ onPage.content.option4}}</span></li>
                            <li><span><i class='bx bx-check'></i>{{ onPage.content.option5}}</span></li>
                            <li><span><i class='bx bx-check'></i>{{ onPage.content.option6}}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'CreativeSolutions',

    setup(){

        const onPage = reactive({
            content: '',
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{

            let content = await loadInfo('services-details-content?populate=image1&populate=image2')
            // console.log('Content Details:', content.data.attributes)
            onPage.content = content.data.attributes

        })
  
        return{
            onPage
        }

    }
}
</script>