<template>
    <div>
        <Navbar />
        <MainBanner />
        <OurFeaturedServices />
        <AboutUs />
        <WhatWeDo />
        <CheckOurServices />
        <OurClientsAreSaying />
        <Partner />
        <OurTeam />
        <OurFlexiblePricingPlan />
        <OurNewsletter />
        <Footer />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import MainBanner from '../HomeOne/MainBanner'
    import OurFeaturedServices from '../Common/OurFeaturedServices'
    import AboutUs from '../Common/AboutUs'
    import WhatWeDo from '../Common/WhatWeDo'
    import CheckOurServices from '../Common/CheckOurServices'
    import OurClientsAreSaying from '../Common/OurClientsAreSaying'
    import Partner from '../Common/Partner'
    import OurTeam from '../Common/OurTeam'
    import OurFlexiblePricingPlan from '../Common/OurFlexiblePricingPlan'
    import OurNewsletter from '../Common/OurNewsletter'
    import Footer from '../Layout/Footer'

    export default {
        name: 'HomeDemoOne',
        components: {
            Navbar,
            MainBanner,
            OurFeaturedServices,
            AboutUs,
            WhatWeDo,
            CheckOurServices,
            OurClientsAreSaying,
            Partner,
            OurTeam,
            OurFlexiblePricingPlan,
            OurNewsletter,
            Footer,
        }
    }
</script>