<template>
    <div>
        <NavbarStyleFour />
        <PageTitle />
        <PricingContent />
        <StartYourProjectWithUs />
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleFour from '../Layout/NavbarStyleFour'
    import PageTitle from '../Pricing/PageTitle'
    import PricingContent from '../Pricing/PricingContent'
    import StartYourProjectWithUs from '../Common/StartYourProjectWithUs'
    import Footer from '../Layout/Footer'

    export default {
        name: 'PricingPage',
        components: {
            NavbarStyleFour,
            PageTitle,
            PricingContent,
            StartYourProjectWithUs,
            Footer,
        }
    }
</script>