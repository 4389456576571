<template>
    <div class="blog-area bg-f9f9f9 ptb-100">
        <div class="container">
            <div class="row" v-if="onPage.content != ''">


                <div class="col-lg-6 col-md-6" v-for="item in onPage.content" :key="item.id">
                    <div class="single-blog-post">
                        <div class="image">
                            
                            <router-link :to="`/blogs/${item.attributes.slug}`" class="d-block">
                                <img :src="'https://strapi.avalon.co.il'+item.attributes.image_main.data.attributes.url" alt="image">
                            </router-link>
                        </div>

                        <div class="content">
                            <h3><router-link :to="`/blogs/${item.attributes.slug}`">{{ item.attributes.content_title }}</router-link></h3>
                            <div class="d-flex align-items-center">
                                <img :src="'https://strapi.avalon.co.il'+item.attributes.author_image.data.attributes.url" alt="image">
                                <div class="info">
                                    <h5>{{ item.attributes.author_name }}</h5>
                                    <span>{{ item.attributes.updated_content }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- <div class="col-lg-12 col-md-12">
                    <div class="pagination-area text-center">
                        <a href="#" class="prev page-numbers"><i class='bx bx-chevrons-right'></i></a>
                        <span class="page-numbers current" aria-current="page">1</span>
                        <a href="#" class="page-numbers">2</a>
                        <a href="#" class="page-numbers">3</a>
                        <a href="#" class="page-numbers">4</a>
                        <a href="#" class="next page-numbers"><i class='bx bx-chevrons-left'></i></a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'BlogOneContent',

    setup(){
        const onPage = reactive({
            content: ''
        })

        const { loadInfo} = connectAxios()

        onMounted(async()=>{
            const content = await loadInfo('blog-detail-collections?populate=image_main&populate=author_image&fields[0]=slug&fields[1]=page_title&fields[2]=author_name&fields[3]=updated_content&fields[4]=content_title')
            // console.log('Blog Content:', content.data)
            onPage.content = content.data

        })

        return{
            onPage
        }
    }
}
</script>