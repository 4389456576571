<template>
    <div class="portfolio-area ptb-100">
        <div class="container">
            <div class="row" v-if="onPage.portfolio != ''">

                <div class="col-lg-6 col-md-6" v-for="item in onPage.portfolio" :key="item.id">
                    <div class="single-portfolio-item">
                        <router-link :to="`/portfolio/${item.attributes.slug}`" class="image d-block">
                            <img :src="'https://strapi.avalon.co.il'+item.attributes.main_image.data.attributes.url" alt="image">
                        </router-link>

                        <div class="content">
                            <h3><router-link :to="`/portfolio/${item.attributes.slug}`">{{ item.attributes.title }}</router-link></h3>
                            <router-link :to="`/portfolio/${item.attributes.slug}`" class="link-btn"><i class="flaticon-next-button"></i></router-link>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-lg-12 col-md-12">
                    <div class="pagination-area text-center">
                        <a href="#" class="prev page-numbers"><i class='bx bx-chevrons-right'></i></a>
                        <span class="page-numbers current" aria-current="page">1</span>
                        <a href="#" class="page-numbers">2</a>
                        <a href="#" class="page-numbers">3</a>
                        <a href="#" class="page-numbers">4</a>
                        <a href="#" class="next page-numbers"><i class='bx bx-chevrons-left'></i></a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"


export default {
    name: 'PortfolioColumnsTwoContent',

    setup(){
        const onPage = reactive({
            portfolio: ''
        })

        const { loadInfo} = connectAxios()

        onMounted(async()=>{
            const content = await loadInfo('portfolio-collections?populate=main_image&fields[0]=slug&fields[1]=title')
            console.log('Portfolio Content:', content.data)
            onPage.portfolio = content.data

        })

        return{
            onPage
        }
    }

}
</script>