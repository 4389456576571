<template>
    <div class="project-start-area ptb-100">
        <div class="container" v-if="onPage.content != ''">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="project-start-image">
                        <img :src="'https://strapi.avalon.co.il'+onPage.content.image.data.attributes.url" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="project-start-content">
                        <span class="sub-title">{{ onPage.content.subtitle }}</span>
                        <h2>{{ onPage.content.title }}</h2>
                        <div v-html="onPage.content.description"></div>
                        <router-link to="/contact" class="default-btn">{{ onPage.content.btn_title }}</router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape2"><img src="../../assets/images/shape/shape2.png" alt="image"></div>
        <div class="shape3"><img src="../../assets/images/shape/shape3.png" alt="image"></div>
        <div class="shape5"><img src="../../assets/images/shape/shape5.png" alt="image"></div>
        <div class="shape6"><img src="../../assets/images/shape/shape6.png" alt="image"></div>
        <div class="shape7"><img src="../../assets/images/shape/shape7.png" alt="image"></div>
        <div class="shape13"><img src="../../assets/images/shape/shape13.png" alt="image"></div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'StartYourProjectWithUs',
    setup(){
        const onPage = reactive({
            content: ''
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{

            let content = await loadInfo('start-project-with-us?populate=image')
            // console.log('Your Project:', content.data.attributes)
            onPage.content = content.data.attributes

        })

        return{
            onPage
        }
    }
}
</script>