<template>
    <div class="services-area bg-f9f9f9 pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">{{ onPage.header.block_title }}</span>
                <h2>{{ onPage.header.title }}</h2>
                <p>{{ onPage.header.description }}</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6" v-for="item in onPage.content" :key="item.id">
                    <div class="single-services-box">
                        <div class="icon">
                            <i :class="item.attributes.icon"></i>
                            <div class="circles-box">
                                <div class="circle-one"></div>
                                <div class="circle-two"></div>
                            </div>
                        </div>
                        <h3><router-link to="/services-details">{{ item.attributes.title }}</router-link></h3>
                        <p>{{ item.attributes.description }}</p>
                        <router-link to="/services-details" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i>{{ item.attributes.btn_title }} <i class="right-icon flaticon-next-button"></i></router-link>
                    </div>
                </div>

               
            </div>
        </div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'CheckOurServices',

    setup(){

        const onPage = reactive({
            header: '',
            content: ''

        })

        const { loadInfo } = connectAxios();

        onMounted(async()=>{

            let header = await loadInfo('service')
            onPage.header = header.data.attributes

            let content = await loadInfo('service-contents')
            onPage.content = content.data
        })


        return {
            onPage
        }


    }
}
</script>