<template>
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <h1>{{ onPage.title }}</h1>
            </div>
        </div>

        <div class="shape2"><img src="../../assets/images/shape/shape2.png" alt="image"></div>
        <div class="shape3"><img src="../../assets/images/shape/shape3.png" alt="image"></div>
        <div class="shape5"><img src="../../assets/images/shape/shape5.png" alt="image"></div>
        <div class="shape6"><img src="../../assets/images/shape/shape6.png" alt="image"></div>
        <div class="shape7"><img src="../../assets/images/shape/shape7.png" alt="image"></div>
        <div class="shape8"><img src="../../assets/images/shape/shape8.png" alt="image"></div>
        <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

//privacy-policy-title
export default {
    name: 'PageTitle',

    setup(){

        const onPage = reactive({
            title:''
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{
            let title = await loadInfo('privacy-policy-title?fields[0]=title')
            // console.log('Private Policy:', title.data.attributes.title)
            onPage.title = title.data.attributes.title
        })


        return{
            onPage
        }
    }
}
</script>