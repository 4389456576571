<template>
    <div>
        <div class="main-banner-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                       
                            <span class="sub-title">{{onPage.sub_title}}</span>
                            <h1>{{onPage.main_title}}</h1>
                            <p>{{ onPage.description }}</p>
                            <div class="btn-box">
                                <div class="d-flex align-items-center">
                                    <router-link to="/contact" class="default-btn">
                                     {{ onPage.get_started }}
                                    </router-link>
                                    <div 
                                        class="video-btn"
                                        v-on:click="isPopupMethod(isPopup)"
                                        v-if="onPage.video_url != null"
                                        style="cursor: pointer"
                                    >
                                        <i class="flaticon-play-button"></i>
                                        {{ onPage.watch_video }} 
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-image the_start_img" v-if="onPage.main_img">
                            
                                <img class="img_small" :src="'https://strapi.avalon.co.il'+onPage.main_img.data.attributes.url" alt="image">
                       
                            </div>
                    </div>
                </div>
            </div>

            <div class="shape1"><img src="../../assets/images/shape/shape1.png" alt="image"></div>
            <div class="shape2"><img src="../../assets/images/shape/shape2.png" alt="image"></div>
            <div class="shape3"><img src="../../assets/images/shape/shape3.png" alt="image"></div>
            <div class="shape4"><img src="../../assets/images/shape/shape4.png" alt="image"></div>
            <div class="shape5"><img src="../../assets/images/shape/shape5.png" alt="image"></div>
            <div class="shape6"><img src="../../assets/images/shape/shape6.png" alt="image"></div>
            <div class="shape7"><img src="../../assets/images/shape/shape7.png" alt="image"></div>
            <div class="shape8"><img src="../../assets/images/shape/shape8.png" alt="image"></div>
        </div>
        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <!-- {{ https://www.youtube.com/watch?v=oTxgwvQW7mg }} -->
                        <iframe :src="onPage.video_url"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import connectAxios from "../../service/getInfo"

export default {
    name: 'MainBanner',
    data (){
        
        return {
            isPopup: false,
            onPage: {},
            pageFeatures: {},
        }
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
    async mounted(){

        const { loadInfo } = connectAxios();

        let result = await loadInfo('home-page-title?populate=main_img'); // url

        console.log('MainBanner: ', result)

        this.onPage = result.data.attributes;

    },
}
</script>

<style scoped>
.strapi_img_small, .strapi_img_medium, .strapi_img_large{
    display: none;
}


@media screen and (max-width: 499px){
    .strapi_img_small{
        display: block;
        height: 100%;
        width: 100%;
    }
}

@media screen and (min-width: 500px) and (max-width: 749px){
    .strapi_img_medium{
        display: block;
        height: 100%;
        width: 100%;
    }
}

@media screen and (min-width: 750px){
    .strapi_img_large{
        display: block;
        height: 100%;
        width: 100%;
    }
}



</style>