<template>
    <div class="privacy-policy-area bg-f9f9f9 ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12" v-if="onPage.content != ''">
                    <div class="privacy-policy-content">
                        <img :src="'https://strapi.avalon.co.il'+onPage.content.image.data.attributes.url" alt="image">
                        <p><i>{{ onPage.content.last_updated }}</i></p>
                        <h3>{{ onPage.content.title }}</h3>
                        <blockquote class="blockquote" v-html="onPage.content.dedicated_text">
                        </blockquote>
                        <div v-html="onPage.content.content"></div>
                     
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <aside class="widget-area">
                        <section class="widget widget_insight">
                            <ul>
                                <li><router-link to="/about">{{ onPage.smallMenu.about_us }}</router-link></li>
                                <li><router-link to="/contact">{{ onPage.smallMenu.contact_us }}</router-link></li>
                                <li class="active"><router-link to="/privacy-policy">{{ onPage.smallMenu.privacy_policy }}</router-link></li>
                                <li><router-link to="/terms-of-service">{{ onPage.smallMenu.temrs_of_service }}</router-link></li>
                            </ul>
                        </section>
                    </aside>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

export default {
    name: 'PrivacyPolicyContent',

    setup(){
        const onPage = reactive({
            smallMenu: {
                about_us: 'About Us',
                contact_us: 'Contact Us',
                privacy_policy: 'Privacy policy',
                temrs_of_service: 'Terms of service'
            },
            content: ''
        })

        const { loadInfo } = connectAxios()

        onMounted(async()=>{

            let smallMenu = await loadInfo('pages-small-menu?fields[0]=about_us&fields[1]=contact_us&fields[2]=privacy_policy&fields[3]=temrs_of_service')
            // console.log('Small menu:',smallMenu.data.attributes)
            onPage.smallMenu = smallMenu.data.attributes

            let content = await loadInfo('privacy-policy-content?populate=image')
            // console.log('Content:', content.data.attributes)
            onPage.content = content.data.attributes
        })

        return{
            onPage
        }
    }
}
</script>