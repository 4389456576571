<template>
    <div class="testimonials-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <div class="testimonials-content">

                        <span class="sub-title">{{ onPage.header.block_title }}</span>
                        <h2>{{ onPage.header.title }}</h2>

                        <div class="testimonials-slides" v-if="onPage.content != ''">
                            <carousel 
                                :autoplay="5000"
                                :settings="settings"
                                :wrap-around="true"
                            >
                                <slide 
                                    v-for="slide in onPage.content" 
                                    :key="slide.id"
                                >
                                    <div class="single-testimonials-item">
                                        <p>{{slide.attributes.text}}</p>
                                        
                                        <div class="client-info">
                                            <div class="d-flex align-items-center">
                                                <img :src="'https://strapi.avalon.co.il'+slide.attributes.avatar.data.attributes.url" alt="image">
                                                <div class="title">
                                                    <h3>{{slide.attributes.name}}</h3>
                                                    <span>{{slide.attributes.profession}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </slide>
                    
                                <template #addons>
                                    <Pagination />
                                </template>
                            </carousel>
                        </div>
                    </div>
                </div>
       

                <div class="col-lg-5 col-md-12" v-if="onPage.header != ''">
                    <div class="testimonials-image" :style="{backgroundImage: `url(https://strapi.avalon.co.il${onPage.header.image.data.attributes.url})`}">
                        <img :src="'https://strapi.avalon.co.il'+onPage.header.image.data.attributes.url" >
                        <!-- <img src="../../assets/images/testimonials-img.jpg" alt="image"> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

import { onMounted, reactive } from 'vue';
import connectAxios from "../../service/getInfo"

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'OurClientsAreSaying',
    components: {
        Carousel,
        Slide,
        Pagination,
    },

    setup(){

        const onPage = reactive({
            header: '',
            content: '',
        })

        const { loadInfo } = connectAxios();

        onMounted(async() => {
            
            let header = await loadInfo('testimonial?populate=image')
            onPage.header = header.data.attributes

            let content = await loadInfo('testimonial-contents?populate=avatar')
            onPage.content = content.data

            // console.log('onPage: ',onPage.content)

        })

        const carouselItems = [
            {
                id: 1,
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
                image: require('../../assets/images/user1.jpg'),
                name: 'John Smith',
                position: 'Python Developer',
            },
            {
                id: 2,
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
                image: require('../../assets/images/user2.jpg'),
                name: 'Sarah Taylor',
                position: 'Web Developer',
            },
            {
                id: 3,
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
                image: require('../../assets/images/user3.jpg'),
                name: 'James Anderson',
                position: 'Web Designer',
            },
        ]

       const  settings = {
            itemsToShow: 1,
            snapAlign: 'center',
        }

        return {
            settings,
            carouselItems,
            onPage
        }


    },
    
})
</script>