<template>
    <div>
        <NavbarStyleFour />
        <PageTitle />
        <TeamContent />
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleFour from '../Layout/NavbarStyleFour'
    import PageTitle from '../TeamOne/PageTitle'
    import TeamContent from '../TeamOne/TeamContent'
    import Footer from '../Layout/Footer'

    export default {
        name: 'TeamPageOne',
        components: {
            NavbarStyleFour,
            PageTitle,
            TeamContent,
            Footer,
        }
    }
</script>